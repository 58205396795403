<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col id="desc" class="mx-auto col-11">
				<h1 class="mb-5">Motivation Scale for Taking Librarian Course</h1>
				<p class="text-subtitle-1" id="scroll-to-0">
                    This questionnaire consists of 24 items designed to assess your motivation for enrolling in a librarian course or specializing in library and information science. Please rate each item according to how well it applies to you. If you have already graduated, please respond based on your motivations at the time you were taking the course.<br>
                </p>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<UserScaleAnswerCard
					v-for="(item, index) in scaleitems"
					:key="'card-' + item.scaleitem.id"
					:scaleitem="item.scaleitem"
					:index="index + 1"
				></UserScaleAnswerCard>
				<v-card
					class="mx-auto mt-10"
					max-width="900"
					tile
					flat
					v-if="notAnsweredId.length"
				>
					<p class="mb-5">Unanswered items:</p>
					<v-chip
						v-for="item in notAnsweredId"
						:key="'not-answered-' + item"
						class="ma-1"
						color="error"
						outlined
						link
						@click="scrollTo('scroll-to-' + (item - 1))"
					>
						{{ item }}
					</v-chip>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<UserScaleAnswerDownload
					lang="en"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: -1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapGetters, mapActions } from 'vuex'
import UserScaleAnswerCard from '@/components/UserScaleAnswerCard'
import UserScaleAnswerDownload from '@/components/UserScaleAnswerDownload'

export default {
	name: 'UserSurveyQ2',
	components: {
		UserScaleAnswerCard,
		UserScaleAnswerDownload
	},
	data () {
		return {
			scaleitems: [
			{
				"order": 1,
				"scaleitem": {
					"id": 1,
					"text": "I want to work in a library",
					"factor_id": 1,
					"factor_name": "Desire to Work in a Library"
				},
				"scale_id": 8
				},
				{
				"order": 2,
				"scaleitem": {
					"id": 2,
					"text": "I want to become a librarian",
					"factor_id": 1,
					"factor_name": "Desire to Work in a Library"
				},
				"scale_id": 8
				},
				{
				"order": 3,
				"scaleitem": {
					"id": 3,
					"text": "I am interested in the work of a librarian",
					"factor_id": 1,
					"factor_name": "Desire to Work in a Library"
				},
				"scale_id": 8
				},
				{
				"order": 4,
				"scaleitem": {
					"id": 4,
					"text": "I am considering being a librarian as a future career option",
					"factor_id": 1,
					"factor_name": "Desire to Work in a Library"
				},
				"scale_id": 8
				},
				{
				"order": 5,
				"scaleitem": {
					"id": 5,
					"text": "I want to feel reassured by obtaining a certification",
					"factor_id": 2,
					"factor_name": "Qualification-Oriented Mindset"
				},
				"scale_id": 8
				},
				{
				"order": 6,
				"scaleitem": {
					"id": 6,
					"text": "I want some kind of certification",
					"factor_id": 2,
					"factor_name": "Qualification-Oriented Mindset"
				},
				"scale_id": 8
				},
				{
				"order": 7,
				"scaleitem": {
					"id": 7,
					"text": "I want a certification in the arts and humanities",
					"factor_id": 2,
					"factor_name": "Qualification-Oriented Mindset"
				},
				"scale_id": 8
				},
				{
				"order": 8,
				"scaleitem": {
					"id": 8,
					"text": "I want many certifications",
					"factor_id": 2,
					"factor_name": "Qualification-Oriented Mindset"
				},
				"scale_id": 8
				},
				{
				"order": 9,
				"scaleitem": {
					"id": 9,
					"text": "It is the profession of someone I respect",
					"factor_id": 3,
					"factor_name": "Admiration for a Librarian Acquaintance"
				},
				"scale_id": 8
				},
				{
				"order": 10,
				"scaleitem": {
					"id": 10,
					"text": "I admire the librarian I saw in a school library",
					"factor_id": 3,
					"factor_name": "Admiration for a Librarian Acquaintance"
				},
				"scale_id": 8
				},
				{
				"order": 11,
				"scaleitem": {
					"id": 11,
					"text": "I admire the librarian I saw in a public library",
					"factor_id": 3,
					"factor_name": "Admiration for a Librarian Acquaintance"
				},
				"scale_id": 8
				},
				{
				"order": 12,
				"scaleitem": {
					"id": 12,
					"text": "An acquaintance has a librarian certification",
					"factor_id": 3,
					"factor_name": "Admiration for a Librarian Acquaintance"
				},
				"scale_id": 8
				},
				{
				"order": 13,
				"scaleitem": {
					"id": 13,
					"text": "I like books",
					"factor_id": 4,
					"factor_name": "Attachment to Books and Reading"
				},
				"scale_id": 8
				},
				{
				"order": 14,
				"scaleitem": {
					"id": 14,
					"text": "I like reading",
					"factor_id": 4,
					"factor_name": "Attachment to Books and Reading"
				},
				"scale_id": 8
				},
				{
				"order": 15,
				"scaleitem": {
					"id": 15,
					"text": "It is a certification related to books and reading",
					"factor_id": 4,
					"factor_name": "Attachment to Books and Reading"
				},
				"scale_id": 8
				},
				{
				"order": 16,
				"scaleitem": {
					"id": 16,
					"text": "I like places/spaces with books",
					"factor_id": 4,
					"factor_name": "Attachment to Books and Reading"
				},
				"scale_id": 8
				},
				{
				"order": 17,
				"scaleitem": {
					"id": 17,
					"text": "I think the librarian certification will be useful for future work",
					"factor_id": 5,
					"factor_name": "Expectations for Librarian Certification"
				},
				"scale_id": 8
				},
				{
				"order": 18,
				"scaleitem": {
					"id": 18,
					"text": "I think the content of the librarian course will be useful for future work",
					"factor_id": 5,
					"factor_name": "Expectations for Librarian Certification"
				},
				"scale_id": 8
				},
				{
				"order": 19,
				"scaleitem": {
					"id": 19,
					"text": "I think the librarian certification will be useful when job hunting",
					"factor_id": 5,
					"factor_name": "Expectations for Librarian Certification"
				},
				"scale_id": 8
				},
				{
				"order": 20,
				"scaleitem": {
					"id": 20,
					"text": "I think the content of the librarian course will be useful for future life",
					"factor_id": 5,
					"factor_name": "Expectations for Librarian Certification"
				},
				"scale_id": 8
				},
				{
				"order": 21,
				"scaleitem": {
					"id": 21,
					"text": "I want to work in a bookstore",
					"factor_id": 6,
					"factor_name": "Desire to Work in the Publishing Industry"
				},
				"scale_id": 8
				},
				{
				"order": 22,
				"scaleitem": {
					"id": 22,
					"text": "I want to work in a publishing house",
					"factor_id": 6,
					"factor_name": "Desire to Work in the Publishing Industry"
				},
				"scale_id": 8
				},
				{
				"order": 23,
				"scaleitem": {
					"id": 23,
					"text": "I want to be involved in the publishing or selling of books",
					"factor_id": 6,
					"factor_name": "Desire to Work in the Publishing Industry"
				},
				"scale_id": 8
				},
				{
				"order": 24,
				"scaleitem": {
					"id": 24,
					"text": "I want to work in a book-related job outside of libraries",
					"factor_id": 6,
					"factor_name": "Desire to Work in the Publishing Industry"
				},
				"scale_id": 8
				}
			],
		}
	},
	computed: {
		...mapGetters({
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitems.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	methods: {
		...mapActions([
			'setScaleItemNotAnswered',
			'setQ2',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		},
		scrollTo(id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		}
	}
}
</script>